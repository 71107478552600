.machine-configuration-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;

    .machine-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        background-color: var(--secondary-bg-color);
        color: var(--primary-font-color);
        padding: 10px;

        header {
            padding: 10px;
        }
    }

    .equipment-selection {
        display: block;
        flex-direction: column;
        overflow: hidden;
        flex-basis: 50%;
    }

    .icon-button {
        color: var(--recorder-btn-secondary-color);
        display: flex;
        padding: 13px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        min-width: 150px;
        background-color: var(--recorder-btn-bg-color);
        font-size: 24px;
        border: none;
        justify-content: center;
    }

    .icon-button:hover {
        background-color: var(--recorder-btn-bg-color-hover);
        cursor: pointer;
    }

    .icon-button.disabled {
        background-color: var(--primary-button-disabled);
    }

    .icon-button.disabled:hover {
        background-color: var(--recorder-btn-action-color-hover);
        cursor: default;
    }

    .icon-button .icon {
        font-size: 24px;
    }

    .record.icon-button.configure {
        display: flex;
        justify-content: space-between;
        background-color: var(--recorder-btn-secondary-bg-color);
        flex-direction: row;
    }
}

@media (max-width: 1200px) {
    .machine-configuration-wrapper {
        flex-direction: column;
        gap: 5px;
    }
}
