#base {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--primary-bg-color);
    height: 100%;
    font-size: 24px;
    color: white;
}

#main-container {
    position: relative;
    display: flex;
    flex-grow: 1;
}

#main-container-content {
    width: 100%;
    display: block;
    padding: 15px;
}

.jobs-container-wrapper {
    display: block;
    flex-grow: 1;
    align-items: flex-start;
    width: 100%;
}

.machines-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 50px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
    padding: 30px;
}

.jobs-container {
    display: flex;
    flex-grow: 1;
    gap: 50px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
    padding: 30px;
}

.jobs-list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.jobs-form {
    display: flex;
    flex-direction: column;
    font-size: 24px;
}

.jobs-form div {
    padding-top: 20px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.jobs-form label {
    text-wrap: nowrap;
    font-size: 18px;
}

.jobs-form div div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.jobs-form textarea {
    font-size: 18px;
    display: flex;
    flex-grow: 1;
    border-width: 1px;
    padding: 5px;
    background-color: var(--primary-input-color);
    resize: vertical;
}

.session-tile input {
    font-size: 18px;
    margin: 0;
    padding-block: 0;
    padding-inline: 0;
    border-width: 1px;
}

.jobs-form select,
.jobs-form input,
.jobs-form button {
    font-size: 18px;
    width: 300px;
    margin: 0;
    padding-block: 0;
    padding-inline: 0;
    border-width: 1px;
}

.jobs-form button,
button.form-button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    background-color: var(--recorder-btn-bg-color);
    color: var(--recorder-btn-secondary-color);
    padding: 20px;
    border: none;
}

.jobs-form button:hover,
button.form-button:hover {
    cursor: pointer;
    background-color: var(--recorder-btn-secondary-bg-color);
}

.session-tile input,
.jobs-form input,
.jobs-form select {
    padding: 10px;
    background-color: var(--primary-input-color);
}

.spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 2px solid #fff;
    width: 12px;
    height: 12px;
    animation: spin 0.6s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1400px) {
    #main-container-content {
        padding: 0px;
    }
}

/* Media Query for small screens */
@media (max-width: 800px) {
    #main-container {
        flex-direction: column;
    }

    .machines-container,
    .jobs-container {
        padding: 10px;
    }

    .jobs-form {
        width: 100%;
    }

    .jobs-form div div {
        flex-direction: column;
    }

    .session-tile input {
        padding: 0px;
        padding-block: 10px;
    }

    .jobs-form textarea,
    .jobs-form select,
    .jobs-form input,
    .jobs-form button,
    .jobs-form button,
    button.form-button {
        width: 100%;
        padding: 0px;
        padding-block: 10px;
    }
}
