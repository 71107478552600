.user-manager-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    width: 100%;

    .user-management {
        display: flex;
        flex-direction: column;
        padding: 0px;
        color: var(--primary-font-color);
        background-color: var(--secondary-bg-color);
        padding: 30px;

        input,
        select {
            min-width: 300px;
            height: 100%;
            border-radius: 5px 0px 0px 5px;
            margin: 0;
            padding: 10px;
            font-size: 13px;
            border-color: var(--primary-bg-color);
            border-width: 1px;
        }

        input:focus-visible,
        select:focus-visible {
            outline: none;
        }

        button {
            padding: 10px;
            height: 100%;
            border-radius: 0px 5px 5px 0px;
            border: none;
            background-color: var(--recorder-btn-bg-color);
            color: #fff;
        }

        button:hover {
            cursor: pointer;
            background-color: var(--recorder-btn-bg-color-hover);
        }

        .modify-groups {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .existing-groups {
                display: flex;
                flex-direction: column;

                .groups {
                    font-size: 13px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;

                    > div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &:hover {
                            background-color: var(--light-background-hover);
                            border-radius: 5px 0px 0 5px;
                        }

                        > div {
                            display: flex;
                            flex-grow: 1;
                            height: 100%;
                            align-items: center;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }

        .user-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .user {
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                padding-top: 10px;
                border-top: 1px solid #cfcfcf;
                align-items: flex-start;
                flex-wrap: wrap;
                overflow: hidden;

                &.no-divider {
                    border-top: none;
                }

                .metadata {
                    display: flex;
                    flex-wrap: wrap;

                    .user-name {
                        width: 200px;
                    }

                    .user-roles {
                        width: 400px;
                    }

                    .groups {
                        width: 200px;
                        display: flex;
                        flex-direction: column;

                        .toggle-groups-button {
                            background: none;
                            border: none;
                            color: #007bff;
                            cursor: pointer;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            padding: 0;
                        }

                        .group-list {
                            margin-top: 5px;
                            padding-left: 20px;
                            display: flex;
                            flex-direction: column;
                            gap: 5px;

                            .group-entry {
                                display: flex;
                                justify-content: space-between;
                                gap: 50px;
                            }
                        }
                    }
                }

                .add-group-to-user {
                    height: 50px;
                    width: 400px;
                    display: flex;
                    align-items: center;

                    max-width: 100%;
                    overflow: hidden;

                    .no-groups-available {
                        color: #888;
                        font-size: 14px;
                        font-style: italic;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .user-manager-wrapper {
        .user-management {
            padding: 10px;

            .modify-groups {
                .new-group {
                    input,
                    button {
                        width: -webkit-fill-available;
                        border-radius: 2px;
                    }
                }

                .existing-groups {
                    .groups {
                        button {
                            border-radius: 2px;
                        }
                    }
                }
            }

            .user-list {
                .user {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;

                    .metadata {
                        width: 100%;
                        gap: 20px;

                        .user-name,
                        .user-roles,
                        .groups {
                            width: 100%;
                        }
                    }

                    .add-group-to-user {
                        height: unset;
                        width: 100%;
                        flex-direction: column;

                        select,
                        button {
                            width: 100%;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
    }
}
